body {
  background: #ececec;
  font-family: 'segoe ui', 'microsoft jhenghei', 'microsoft mhei', stheititc,
    sans-serif;
}

h1,
h2,
h3 {
  color: #2f5369;
}

h3 {
  margin: 8px 0;
}

.btnPrimary {
  background-color: #5b809b !important;
}

.btnChange {
  background-color: #889dbc !important;
}

.ql-editor {
  min-height: 100px !important;
  max-height: 600px;
  overflow: hidden;
  overflow-y: auto;
  overflow-x: auto;
}

.ql-editor h1,
.rq-text h1 {
  color: #6f342a !important;
  font-weight: 800 !important;
  font-size: 18px !important;
  margin: 2px 0;
}

.ql-editor h2,
.rq-text h2 {
  color: #6f342a !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  margin: 1px 0;
}

.ql-editor p,
.rq-text p {
  color: #222 !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  margin: 1px 0;
}

.ql-editor li,
.rq-text li {
  color: #222 !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  list-style: none;
}

.ql-editor .ql-video,
.rq-text .ql-video {
  height: 450px;
  width: 100%;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='16px']::before {
  content: '16px';
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='16px']::before {
  content: '16px';
  font-size: 16px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='18px']::before {
  content: '18px';
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='18px']::before {
  content: '18px';
  font-size: 16px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='20px']::before {
  content: '20px';
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='20px']::before {
  content: '20px';
  font-size: 16px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='24px']::before {
  content: '24px';
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='24px']::before {
  content: '24px';
  font-size: 16px !important;
}
